import Video from '@/models/Video'
import Api from '@/services/api'
import { each, find } from 'lodash'

export default {
  namespaced: true,
  state: {
    list: [],
    listMeta: {
      current_page: 0,
      last_page: 1,
    },
  },

  mutations: {
    setVideoList(state, videos) {
      each(videos, (video) => {
        const exist = find(state.list, { id: video.id })
        if (!exist) {
          state.list.push(new Video(video))
        }
      })
    },

    clearVideoList(state) {
      state.list = []
    },

    clearVideoDetails(state) {
      state.videoDetails = null
    },

    setVideoListMeta(state, meta) {
      state.listMeta = meta
    },

    setVideoDetails(state, video) {
      if (video instanceof Video) {
        state.videoDetails = video
      } else {
        state.videoDetails = new Video(video)
      }
    },
  },

  actions: {
    async getVideos({ commit }, params) {
      const query = Video.page(params.page || 1).orderBy(params.sort)

      if (params.search) {
        query.where('search', params.search)
      }

      const res = await query
        .params({ limit: 20, 'filter[type]': params.type })
        .get()

      commit('setVideoList', res.data)
      commit('setVideoListMeta', res.meta)
    },

    async getVideoDetails({ commit }, id) {
      const { data } = await Api.get(`admin/videos/${id}`)

      commit('setVideoDetails', data.data)
    },

    async storeVideo({ commit }, formData) {
      const { data } = await Api.post(`admin/videos`, formData)
      commit('setVideoDetails', data.data)
    },

    async updateVideo({ commit }, formData) {
      const { data } = await Api.post(
        `admin/videos/${formData.id}`,
        formData.data
      )

      commit('setVideoDetails', data.data)
    },

    async deleteVideo({ commit }, id) {
      await Api.delete(`admin/videos/${id}`)
    },
  },
}
