import Model from './Model'
import Avatar from './Avatar'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
dayjs.extend(utc)

export default class User extends Model {
  resource() {
    return 'admin/users'
  }

  avatar() {
    return this.hasMany(Avatar)
  }

  get avatarText() {
    return this.first_name.charAt(0) + this.last_name.charAt(0)
  }

  get addedDate() {
    return dayjs.utc(this.created_at).local().format('MMM DD, YYYY hh:mm A')
  }

  get startDate() {
    let date = null
    if (this.no_expiration) {
      date = dayjs.utc(this.created_at).local().format('MMM DD, YYYY hh:mm A')
    }
    if (this.user_subscription && this.user_subscription.start_at) {
      date = dayjs
        .utc(this.user_subscription.start_at)
        .local()
        .format('MMM DD, YYYY hh:mm A')
    }
    return date
  }

  get endDate() {
    let date = null
    if (this.no_expiration) {
      return 'No Expiration'
    }
    if (this.user_subscription && this.user_subscription.end_at) {
      date = dayjs
        .utc(this.user_subscription.end_at)
        .local()
        .format('MMM DD, YYYY hh:mm A')
    }
    return date
  }

  get trialEnds() {
    let date = null
    if (this.user_subscription && this.user_subscription.trial_ends_at) {
      date = dayjs
        .utc(this.user_subscription.trial_ends_at)
        .local()
        .format('MMM DD, YYYY hh:mm A')
    }
    return date
  }

  get renewalDate() {
    let date = null
    if (this.user_subscription && this.user_subscription.renewal_date) {
      date = dayjs
        .utc(this.user_subscription.renewal_date)
        .local()
        .format('MMM DD, YYYY hh:mm A')
    }
    return date
  }
}
