import Vue from 'vue'
import Router from 'vue-router'
import auth from './auth'
import settings from './settings'

Vue.use(Router)

export default new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    auth,
    {
      path: '/',
      name: 'home',
      redirect: { name: 'users' },
      component: () => import(/* webpackChunkName: "home" */ '@/views/Home'),
      children: [
        {
          path: 'inbox',
          name: 'inbox',
          component: () =>
            import(/* webpackChunkName: "inbox" */ '@/views/Home/Inbox'),
        },
        {
          path: 'users',
          name: 'users',
          component: () =>
            import(/* webpackChunkName: "users" */ '@/views/Home/Users'),
        },
        {
          path: 'users/:id',
          name: 'user.details',
          component: () =>
            import(
              /* webpackChunkName: "users" */ '@/views/Home/Users/UserDetails'
            ),
        },
        {
          path: 'admin/:id',
          name: 'admin.details',
          component: () =>
            import(
              /* webpackChunkName: "admin" */ '@/views/Home/Admin/AdminDetails'
            ),
        },
        {
          path: 'categories',
          name: 'categories',
          component: () =>
            import(
              /* webpackChunkName: "categories" */ '@/views/Home/Categories'
            ),
        },
        {
          path: 'categories/create',
          name: 'categories.create',
          component: () =>
            import(
              /* webpackChunkName: "categories-create" */ '@/views/Home/Categories/CategoryCreate'
            ),
        },
        {
          path: 'categories/:id',
          name: 'categories.details',
          component: () =>
            import(
              /* webpackChunkName: "categories-details" */ '@/views/Home/Categories/CategoryDetails'
            ),
        },
        {
          path: 'exercises',
          name: 'exercises',
          component: () =>
            import(
              /* webpackChunkName: "exercises" */ '@/views/Home/Exercises'
            ),
        },
        {
          path: 'exercises/create',
          name: 'exercises.create',
          component: () =>
            import(
              /* webpackChunkName: "exercises-create" */ '@/views/Home/Exercises/ExerciseCreate'
            ),
        },
        {
          path: 'exercises/:id',
          name: 'exercises.details',
          component: () =>
            import(
              /* webpackChunkName: "exercises-details" */ '@/views/Home/Exercises/ExerciseDetails'
            ),
        },
        {
          path: 'exercises-items',
          name: 'exercises-items',
          component: () =>
            import(
              /* webpackChunkName: "exercises-items" */ '@/views/Home/ExerciseItems'
            ),
        },
        {
          path: 'exercises-items/create',
          name: 'exercises-items.create',
          component: () =>
            import(
              /* webpackChunkName: "exercises-items-create" */ '@/views/Home/ExerciseItems/ExerciseItemCreate'
            ),
        },
        {
          path: 'exercises-items/:id',
          name: 'exercises-items.details',
          component: () =>
            import(
              /* webpackChunkName: "exercises-items-details" */ '@/views/Home/ExerciseItems/ExerciseItemDetails'
            ),
        },
        {
          path: 'courses',
          name: 'courses',
          component: () =>
            import(/* webpackChunkName: "courses" */ '@/views/Home/Courses'),
        },
        {
          path: 'courses/create',
          name: 'courses.create',
          component: () =>
            import(
              /* webpackChunkName: "courses-create" */ '@/views/Home/Courses/CourseCreate'
            ),
        },
        {
          path: 'courses/:id',
          name: 'courses.details',
          component: () =>
            import(
              /* webpackChunkName: "courses-details" */ '@/views/Home/Courses/CourseDetails'
            ),
        },
        {
          path: 'course-categories',
          name: 'course-categories',
          component: () =>
            import(
              /* webpackChunkName: "courses-categories" */ '@/views/Home/CourseCategories'
            ),
        },
        {
          path: 'course-categories/create',
          name: 'course-categories.create',
          component: () =>
            import(
              /* webpackChunkName: "course-categories-create" */ '@/views/Home/CourseCategories/CourseCategoryCreate'
            ),
        },
        {
          path: 'course-categories/:id',
          name: 'course-categories.details',
          component: () =>
            import(
              /* webpackChunkName: "course-categories-details" */ '@/views/Home/CourseCategories/CourseCategoryDetails'
            ),
        },
        {
          path: 'banners',
          name: 'banners',
          component: () =>
            import(/* webpackChunkName: "banners" */ '@/views/Home/Banners'),
        },
        {
          path: 'banners/create',
          name: 'banners.create',
          component: () =>
            import(
              /* webpackChunkName: "banner-create" */ '@/views/Home/Banners/BannerCreate'
            ),
        },
        {
          path: 'banners/:id',
          name: 'banners.details',
          component: () =>
            import(
              /* webpackChunkName: "banner-details" */ '@/views/Home/Banners/BannerDetails'
            ),
        },
        // Video
        {
          path: 'videos',
          name: 'videos',
          component: () =>
            import(/* webpackChunkName: "videos" */ '@/views/Home/Videos'),
        },
        {
          path: 'videos/create',
          name: 'videos.create',
          component: () =>
            import(
              /* webpackChunkName: "videos-create" */ '@/views/Home/Videos/VideoCreate'
            ),
        },
        {
          path: 'videos/:id',
          name: 'videos.details',
          component: () =>
            import(
              /* webpackChunkName: "videos-details" */ '@/views/Home/Videos/VideoDetails'
            ),
        },
        {
          path: 'content',
          name: 'content',
          component: () =>
            import(/* webpackChunkName: "content" */ '@/views/Home/Content'),
        },
        {
          path: 'notification',
          name: 'notification',
          component: () =>
            import(
              /* webpackChunkName: "push-notification" */ '@/views/Home/Notification'
            ),
        },
        {
          path: 'campaigns',
          name: 'campaigns',
          component: () =>
            import(
              /* webpackChunkName: "campaigns" */ '@/views/Home/Campaigns'
            ),
        },
        {
          path: 'campaigns/send',
          name: 'campaigns.send',
          component: () =>
            import(
              /* webpackChunkName: "campaigns-send" */ '@/views/Home/Campaigns/SendCampaign'
            ),
        },
        settings,
        {
          path: 'reports',
          name: 'reports',
          component: () =>
            import(/* webpackChunkName: "reports" */ '@/views/Home/Reports'),
        },
      ],
    },
  ],
})
