import Campaign from '@/models/Campaign'
import Api from '@/services/api'
import { each, find } from 'lodash'

export default {
  namespaced: true,
  state: {
    list: [],
    listMeta: {
      current_page: 0,
      last_page: 1,
    },
  },

  mutations: {
    setCampaignList(state, campaigns) {
      each(campaigns, (campaign) => {
        const exist = find(state.list, { id: campaign.id })
        if (!exist) {
          state.list.push(new Campaign(campaign))
        }
      })
    },

    clearCampaignList(state) {
      state.list = []
    },

    clearCampaignDetails(state) {
      state.campaignDetails = null
    },

    setCampaignListMeta(state, meta) {
      state.listMeta = meta
    },

    setCampaignDetails(state, campaign) {
      if (campaign instanceof Campaign) {
        state.campaignDetails = campaign
      } else {
        state.campaignDetails = new Campaign(campaign)
      }
    },
  },

  actions: {
    async getCampaigns({ commit }, params) {
      const query = Campaign.page(params.page || 1).orderBy(params.sort)
      if (params.search) {
        query.where('search', params.search)
      }

      const res = await query
        .params({ limit: 20, 'filter[type]': params.type })
        .get()

      commit('setCampaignList', res.data)
      commit('setCampaignListMeta', res.meta)
    },

    async getCampaignDetails({ commit }, id) {
      const { data } = await Api.get(`admin/campaigns/${id}`)

      commit('setCampaignDetails', data.data)
    },

    async storeCampaign({ commit }, formData) {
      const { data } = await Api.post(`admin/campaigns`, formData)
      commit('setCampaignDetails', data.data)
    },

    async sendCampaign({ commit }, formData) {
      const { data } = await Api.post(`admin/campaigns/send-to-all`, formData)
      commit('setCampaignDetails', data.data)
    },

    async updateCampaign({ commit }, formData) {
      const { data } = await Api.post(
        `admin/campaigns/${formData.id}`,
        formData.data
      )

      commit('setCampaignDetails', data.data)
    },

    async deleteCampaign({ commit }, id) {
      await Api.delete(`admin/campaigns/${id}`)
    },
  },
}
