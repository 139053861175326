import CourseActivity from '@/models/CourseActivity'
import Api from '@/services/api'
import { each, find } from 'lodash'

export default {
  namespaced: true,
  state: {
    list: [],
    listMeta: {
      current_page: 0,
      last_page: 1,
    },
    courseActivity: [],
    courseActivityDetails: [],
  },

  mutations: {
    setCourseActivityList(state, courseActivities) {
      each(courseActivities, (activity) => {
        const exist = find(state.list, { id: activity.id })
        if (!exist) {
          state.list.push(new CourseActivity(activity))
        }
      })
    },

    clearCourseActivityList(state) {
      state.list = []
    },

    clearCourseActivity(state) {
      state.courseActivity = null
    },

    setCourseActivityListMeta(state, meta) {
      state.listMeta = meta
    },

    setCourseActivityDetails(state, courseActivity) {
      if (courseActivity instanceof CourseActivity) {
        state.courseActivityDetails = courseActivity
      } else {
        state.courseActivityDetails = new CourseActivity(courseActivity)
      }
    },

    setCourseActivity(state, activity) {
      state.courseActivity = activity
    },
  },

  actions: {
    async getCourseActivities({ commit }, params) {
      const query = CourseActivity.page(params.page || 1)

      if (params.search) {
        query.where('search', params.search)
      }

      const res = await query
        .params({ limit: 20, 'filter[type]': params.type })
        .get()

      commit('setCourseActivityList', res.data)
      commit('setCourseActivityListMeta', res.meta)
    },

    async getCourseActivity({ commit }, id) {
      const { data } = await Api.get(`admin/courses/${id}/course-activities`, {
        params: {
          per_page: 999,
        },
      })
      commit('setCourseActivity', data.data)
    },

    async getCourseActivityDetails({ commit }, activity) {
      const { data } = await Api.get(
        `admin/courses/${activity.course_id}/course-activities/${activity.activity_id}`
      )

      commit('setCourseActivityDetails', data.data)
    },

    async storeCourseActivity({ commit }, formData) {
      const { data } = await Api.post(
        `admin/courses/${formData.id}/course-activities`,
        formData.data
      )
      commit('setCourseActivity', data.data)
    },

    async updateCourseActivity({ commit }, formData) {
      const { data } = await Api.post(
        `admin/courses/course-activities/${formData.id}`,
        formData.data
      )

      commit('setCourseActivity', data.data)
    },

    async deleteCourseActivity({ commit }, id) {
      await Api.delete(`admin/courses/course-activities/${id}`)
    },
  },
}
