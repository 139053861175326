import Category from '@/models/Category'
import Api from '@/services/api'
import { each, find } from 'lodash'

export default {
  namespaced: true,
  state: {
    list: [],
    listMeta: {
      current_page: 0,
      last_page: 1,
    },
  },

  mutations: {
    setCategoryList(state, categories) {
      each(categories, (category) => {
        const exist = find(state.list, { id: category.id })
        if (!exist) {
          state.list.push(new Category(category))
        }
      })
    },

    clearCategoryList(state) {
      state.list = []
    },

    clearCategoryDetails(state) {
      state.categoryDetails = null
    },

    setCategoryListMeta(state, meta) {
      state.listMeta = meta
    },

    setCategoryDetails(state, category) {
      if (category instanceof Category) {
        state.categoryDetails = category
      } else {
        state.categoryDetails = new Category(category)
      }
    },
  },

  actions: {
    async getCategories({ commit }, params) {
      const query = Category.page(params.page || 1)
        .include('photo')
        .orderBy(params.sort)

      if (params.search) {
        query.where('search', params.search)
      }

      const res = await query
        .params({
          limit: 20,
          'filter[type]': params.type,
          'filter[with_disabled]': true,
        })
        .get()

      commit('setCategoryList', res.data)
      commit('setCategoryListMeta', res.meta)
    },

    async getCategoryDetails({ commit }, id) {
      const { data } = await Api.get(`admin/exercises/categories/${id}`)
      commit('setCategoryDetails', data.data)
    },

    async storeCategory({ commit }, formData) {
      const { data } = await Api.post(`admin/exercises/categories`, formData)
      commit('setCategoryDetails', data.data)
    },

    async updateCategory({ commit }, formData) {
      const { data } = await Api.post(
        `admin/exercises/categories/${formData.id}`,
        formData.data
      )

      commit('setCategoryDetails', data.data)
    },

    async deleteCategory({ commit }, id) {
      await Api.delete(`admin/exercises/categories/${id}`)
    },
  },
}
