import Admin from '@/models/Admin'
import Avatar from '@/models/Avatar'
import Api from '@/services/api'

export default {
  namespaced: true,
  state: {
    list: [],
    listMeta: {
      current_page: 0,
      last_page: 1,
    },
    adminDetails: null,
  },

  mutations: {
    setAdminListMeta(state, meta) {
      state.listMeta = meta
    },

    setAdminDetails(state, admin) {
      if (admin instanceof Admin) {
        state.adminDetails = admin
      } else {
        state.adminDetails = new Admin(admin)
      }
    },

    setSelectedAdminAvatar(state, avatar) {
      if (avatar instanceof Avatar) {
        state.adminDetails.avatar = avatar
      } else {
        state.adminDetails.avatar = new Avatar(avatar)
      }
    },

    clearAdminDetails(state) {
      state.adminDetails = null
    },

    adminAvatarRemoved(state) {
      state.adminDetails.avatar = null
    },
  },

  actions: {
    async getAdminDetails({ commit }, id) {
      const { data } = await Api.get(`admin/${id}`)
      commit('setAdminDetails', data.data)
    },

    async changeAvatar({ commit }, formData) {
      const { data } = await Api.post(
        `admin/${formData.get('admin_id')}/avatar`,
        formData
      )
      commit('setSelectedAdminAvatar', data.data)
    },

    async updateAdmin({ commit }, formData) {
      const { data } = await Api.put(`admin/${formData.id}`, formData)
      commit('setAdminDetails', data.data)
    },
  },
}
