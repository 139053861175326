import Vue from 'vue'
import Vuex from 'vuex'
import auth from './modules/auth'
import user from './modules/user'
import admin from './modules/admin'
import category from './modules/category'
import course from './modules/course'
import courseCategory from './modules/course-category'
import courseActivity from './modules/course-activity'
import exercise from './modules/exercise'
import video from './modules/video'
import banner from './modules/banner'
import campaign from './modules/campaign'
import exerciseItem from './modules/exercise-item'
import notification from './modules/notification'
import report from './modules/report'
import versionControl from './modules/version-control'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    auth,
    user,
    notification,
    category,
    admin,
    exercise,
    report,
    exerciseItem,
    versionControl,
    courseCategory,
    courseActivity,
    course,
    banner,
    campaign,
    video,
  },
  state: {
    controls: {
      showDrawer: true,
    },
  },
  mutations: {
    toggleDrawer(state, value) {
      state.controls.showDrawer = value
    },
  },
})
