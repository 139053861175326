import ExerciseItem from '@/models/ExerciseItem'
import Api from '@/services/api'
import { each, find } from 'lodash'

export default {
  namespaced: true,
  state: {
    list: [],
    listMeta: {
      current_page: 0,
      last_page: 1,
    },
  },

  mutations: {
    setExerciseItemList(state, exerciseItems) {
      each(exerciseItems, (exerciseItem) => {
        const exist = find(state.list, { id: exerciseItem.id })
        if (!exist) {
          state.list.push(new ExerciseItem(exerciseItem))
        }
      })
    },

    clearExerciseItemList(state) {
      state.list = []
    },

    clearExerciseItemDetails(state) {
      state.exerciseItemDetails = null
    },

    setExerciseItemListMeta(state, meta) {
      state.listMeta = meta
    },

    setExerciseItemDetails(state, exerciseItem) {
      if (exerciseItem instanceof ExerciseItem) {
        state.exerciseItemDetails = exerciseItem
      } else {
        state.exerciseItemDetails = new ExerciseItem(exerciseItem)
      }
    },
  },

  actions: {
    async getExerciseItems({ commit }, params) {
      const query = ExerciseItem.page(params.page || 1).orderBy(params.sort)

      if (params.search) {
        query.where('search', params.search)
      }

      const res = await query
        .params({ limit: 20, 'filter[type]': params.type })
        .get()

      commit('setExerciseItemList', res.data)
      commit('setExerciseItemListMeta', res.meta)
    },

    async getExerciseItemDetails({ commit }, id) {
      const { data } = await Api.get(`admin/exercises/items/${id}`)
      commit('setExerciseItemDetails', data.data)
    },

    async storeExerciseItem({ commit }, formData) {
      const { data } = await Api.post(`admin/exercises/items`, formData)
      commit('setExerciseItemDetails', data.data)
    },

    async updateExerciseItem({ commit }, formData) {
      const { data } = await Api.post(
        `admin/exercises/items/${formData.id}`,
        formData.data
      )

      commit('setExerciseItemDetails', data.data)
    },

    async deleteExerciseItem({ commit }, id) {
      await Api.delete(`admin/exercises/items/${id}`)
    },
  },
}
