import Exercise from '@/models/Exercise'
import Api from '@/services/api'
import { each, find } from 'lodash'

export default {
  namespaced: true,
  state: {
    list: [],
    listMeta: {
      current_page: 0,
      last_page: 1,
    },
  },

  mutations: {
    setExerciseList(state, exercises) {
      each(exercises, (exercise) => {
        const exist = find(state.list, { id: exercise.id })
        if (!exist) {
          state.list.push(new Exercise(exercise))
        }
      })
    },

    clearExerciseList(state) {
      state.list = []
    },

    clearExerciseDetails(state) {
      state.exerciseDetails = null
    },

    setExerciseListMeta(state, meta) {
      state.listMeta = meta
    },

    setExerciseDetails(state, exercise) {
      if (exercise instanceof Exercise) {
        state.exerciseDetails = exercise
      } else {
        state.exerciseDetails = new Exercise(exercise)
      }
    },

    setExerciseItemCategory(state, itemCategory) {
      state.exerciseItemCategory = itemCategory
    },
  },

  actions: {
    async getExercises({ commit }, params) {
      const query = Exercise.page(params.page || 1)
        .include([
          'video',
          'exercise_category',
          'photo',
          'before_photo',
          'after_photo',
        ])
        .orderBy(params.sort)

      if (params.search) {
        query.where('search', params.search)
      }

      if (params.exercise_category_id) {
        query.where('exercise_category_id', params.exercise_category_id)
      }

      const res = await query
        .params({ limit: 20, 'filter[type]': params.type })
        .get()

      commit('setExerciseList', res.data)
      commit('setExerciseListMeta', res.meta)
    },

    async getExerciseDetails({ commit }, id) {
      const { data } = await Api.get(`admin/exercises/${id}`)
      commit('setExerciseDetails', data.data)
    },

    async getExerciseItemCategory({ commit }) {
      const { data } = await Api.get(`admin/exercises/create`)

      commit('setExerciseItemCategory', data)
    },

    async storeExercise({ commit }, formData) {
      const { data } = await Api.post(`admin/exercises`, formData)
      commit('setExerciseDetails', data.data)
    },

    async updateExercise({ commit }, formData) {
      const { data } = await Api.post(
        `admin/exercises/${formData.id}`,
        formData.data
      )
      commit('setExerciseDetails', data.data)
    },

    async deleteExercise({ commit }, id) {
      await Api.delete(`admin/exercises/${id}`)
    },
  },
}
