import Banner from '@/models/Banner'
import Api from '@/services/api'
import { each, find } from 'lodash'

export default {
  namespaced: true,
  state: {
    list: [],
    listMeta: {
      current_page: 0,
      last_page: 1,
    },
  },

  mutations: {
    setBannerList(state, banners) {
      each(banners, (banner) => {
        const exist = find(state.list, { id: banner.id })
        if (!exist) {
          state.list.push(new Banner(banner))
        }
      })
    },

    clearBannerList(state) {
      state.list = []
    },

    clearBannerDetails(state) {
      state.bannerDetails = null
    },

    setBannerListMeta(state, meta) {
      state.listMeta = meta
    },

    setBannerDetails(state, banner) {
      if (banner instanceof Banner) {
        state.bannerDetails = banner
      } else {
        state.bannerDetails = new Banner(banner)
      }
    },
  },

  actions: {
    async getBanners({ commit }, params) {
      const query = Banner.page(params.page || 1).orderBy(params.sort)

      if (params.search) {
        query.where('search', params.search)
      }

      const res = await query
        .params({ limit: 20, 'filter[type]': params.type })
        .get()

      commit('setBannerList', res.data)
      commit('setBannerListMeta', res.meta)
    },

    async getBannerDetails({ commit }, id) {
      const { data } = await Api.get(`admin/banners/${id}`)

      commit('setBannerDetails', data.data)
    },

    async storeBanner({ commit }, formData) {
      const { data } = await Api.post(`admin/banners`, formData)
      commit('setBannerDetails', data.data)
    },

    async updateBanner({ commit }, formData) {
      const { data } = await Api.post(
        `admin/banners/${formData.id}`,
        formData.data
      )

      commit('setBannerDetails', data.data)
    },

    async deleteBanner({ commit }, id) {
      await Api.delete(`admin/banners/${id}`)
    },
  },
}
