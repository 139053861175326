import Course from '@/models/Course'
import CourseActivity from '@/models/CourseActivity'
import Api from '@/services/api'
import { each, find } from 'lodash'

export default {
  namespaced: true,
  state: {
    list: [],
    listMeta: {
      current_page: 0,
      last_page: 1,
    },
  },

  mutations: {
    setCourseList(state, courses) {
      each(courses, (course) => {
        const exist = find(state.list, { id: course.id })
        if (!exist) {
          state.list.push(new Course(course))
        }
      })
    },

    clearCourseList(state) {
      state.list = []
    },

    clearCourseDetails(state) {
      state.courseDetails = null
    },

    setCourseListMeta(state, meta) {
      state.listMeta = meta
    },

    setCourseDetails(state, course) {
      if (course instanceof Course) {
        state.courseDetails = course
      } else {
        state.courseDetails = new Course(course)
      }
    },

    setCourseActivityDetails(state, courseActivity) {
      if (courseActivity instanceof CourseActivity) {
        state.courseActivityDetails = courseActivity
      } else {
        state.courseActivityDetails = new CourseActivity(courseActivity)
      }
    },
  },

  actions: {
    async getCourses({ commit }, params) {
      const query = Course.page(params.page || 1)
        .include(['photo', 'activities'])
        .orderBy(params.sort)

      if (params.search) {
        query.where('search', params.search)
      }

      const res = await query
        .params({ limit: 20, 'filter[type]': params.type })
        .get()

      commit('setCourseList', res.data)
      commit('setCourseListMeta', res.meta)
    },

    async getCourseDetails({ commit }, id) {
      const { data } = await Api.get(`admin/courses/${id}`)
      commit('setCourseDetails', data.data)
    },

    async storeCourse({ commit }, formData) {
      const { data } = await Api.post(`admin/courses`, formData)
      commit('setCourseDetails', data.data)
    },

    async updateCourse({ commit }, formData) {
      const { data } = await Api.post(
        `admin/courses/${formData.id}`,
        formData.data
      )

      commit('setCourseDetails', data.data)
    },

    async deleteCourse({ commit }, id) {
      await Api.delete(`admin/courses/${id}`)
    },
  },
}
