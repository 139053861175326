import CourseCategory from '@/models/CourseCategory'
import Api from '@/services/api'
import { each, find } from 'lodash'

export default {
  namespaced: true,
  state: {
    list: [],
    listMeta: {
      current_page: 0,
      last_page: 1,
    },
  },

  mutations: {
    setCourseCategoryList(state, courseCategories) {
      each(courseCategories, (category) => {
        const exist = find(state.list, { id: category.id })
        if (!exist) {
          state.list.push(new CourseCategory(category))
        }
      })
    },

    clearCourseCategoryList(state) {
      state.list = []
    },

    clearCourseCategoryDetails(state) {
      state.courseCategoryDetails = null
    },

    setCourseCategoryListMeta(state, meta) {
      state.listMeta = meta
    },

    setCourseCategoryDetails(state, category) {
      if (category instanceof CourseCategory) {
        state.courseCategoryDetails = category
      } else {
        state.courseCategoryDetails = new CourseCategory(category)
      }
    },
  },

  actions: {
    async getCourseCategories({ commit }, params) {
      const query = CourseCategory.page(params.page || 1).orderBy(params.sort)

      if (params.search) {
        query.where('search', params.search)
      }

      const res = await query
        .params({ limit: 20, 'filter[type]': params.type })
        .get()

      commit('setCourseCategoryList', res.data)
      commit('setCourseCategoryListMeta', res.meta)
    },

    async getCourseCategoryDetails({ commit }, id) {
      const { data } = await Api.get(`admin/courses/course-categories/${id}`)
      commit('setCourseCategoryDetails', data.data)
    },

    async storeCourseCategory({ commit }, formData) {
      const { data } = await Api.post(
        `admin/courses/course-categories`,
        formData
      )
      commit('setCourseCategoryDetails', data.data)
    },

    async updateCourseCategory({ commit }, formData) {
      const { data } = await Api.put(
        `admin/courses/course-categories/${formData.id}`,
        formData
      )

      commit('setCourseCategoryDetails', data.data)
    },

    async deleteCourseCategory({ commit }, id) {
      await Api.delete(`admin/courses/course-categories/${id}`)
    },
  },
}
